::placeholder {
    color: #DADADA;
}

::-ms-input-placeholder {
    color: #DADADA;
}

.intl-tel-input {
    padding: 20px 45px;
    outline: none;
    background-color: black;
    border-radius: 9999px;
    text-transform: uppercase;
    font-size: 20px;
    display: flex !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 3px;
    background-color: transparent !important;
    margin-left: 45px;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    padding-left: 0px !important;
    color: #DADADA !important;
}

.intl-tel-input .flag-container .arrow {
    font-size: 10px !important;
    margin-left: 0px !important;
    color: #DADADA !important;
}

.form-control {
    width: -webkit-fill-available;
    outline: none;
    background-color: transparent !important;
    color: #FFF2E2;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent;
    }

    50% {
        border-color: white;
    }
}

.typing-effect {
    overflow: hidden;
    /* ensures the text is hidden until it's "typed out" */
    white-space: nowrap;
    /* ensures the text doesn't wrap to a new line */
    border-right: .15em solid white;
    /* creates the caret effect */
    animation: typing 3.5s steps(30, end), blink-caret .75s step-end infinite;
}

@media (max-width: 1279px) {
    .intl-tel-input {
        padding: 20px 30px;
        font-size: 16px;
    }

    .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
        margin-left: 25px;
    }
}

@media only screen and (max-width: 1279px) and (min-width: 1024px) {
    .intl-tel-input {
        padding: 20px 45px;
        font-size: 20px;
    }
}