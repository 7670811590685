@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Formula Condensed';
  src: url('assets/fonts/formula-condensed-free-for-personal-use/Formula-Condensed/FormulaCondensed-Light.otf') format('truetype');
}

@font-face {
  font-family: 'PP MORI';
  src: url('assets/fonts/PPMori-Regular.otf') format('truetype');
}

body {
  font-family: 'PP MORI', sans-serif !important;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

::-webkit-scrollbar {
  display: none;
}